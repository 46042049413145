// @ts-nocheck
import axios from "axios";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import ReactLoading from 'react-loading';
import { logEvent,getAnalytics } from "firebase/analytics";
// @ts-ignore
import useGeoLocation from "react-ipgeolocation";
import "react-phone-input-2/lib/style.css";
export default function Congratulations(props) {


	const analytics = getAnalytics()
	const [error, setError] = useState('')
	const [isEmailOk, setIsEmailOk] = useState("")
	const location = useGeoLocation();
	const [Loader, setLoader] = useState(true)
	const [newFormData, setNewFormData] = useState('')
	// @ts-ignore
	const [phoneData, setPhoneData] = useState(null);
	// @ts-ignore
	const [code, setcode] = useState(undefined);

	const [isDisable, setisDisable] = useState(true);
	const [formData, setFormData] = useState({
		email: null,
		name: null,
		mobile: null,
		isd: null,
	});

	const check = (data) => {
		let flag = false;
		Object.keys(data).forEach((item) => {


			if (/^[A-Za-z0-9+_.-]+@(.+)$/.test(data[item])) {
				setIsEmailOk("ok")
				console.log("ok")
			}

			if (isEmailOk == "ok") {
				console.log("mbloutside")
				if (formData?.mobile?.length >= 5) {
					console.log("mbl")
					setisDisable(false)
				}
			}
		});
		// return flag;
	};
	const handleFormData = (e) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;

		setFormData((prev) => {
			const newData = { ...prev };
			newData[name] = value;
			check(newData)

			return newData;
		});

	};
	// @ts-ignore
	const handleChange = (value, data, event, formattedValue) => {
		const mobile = value.slice(data.dialCode.length)
		setPhoneData(mobile)
		setFormData((prev) => ({ ...prev, mobile }))
		setcode(data.dialCode);



	};
	useEffect(() => {
		check(formData)

	}, [formData.mobile])

	const popupCenter = (url, title, w, h) => {
		// Fixes dual-screen position     Most browsers      Firefox
		// console.log(url)
		const dualScreenLeft =
			window.screenLeft !== undefined
				? window.screenLeft
				: window.screenX;
		const dualScreenTop =
			window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth
			? window.innerWidth
			: document.documentElement.clientWidth
				? document.documentElement.clientWidth
				: window.screen.width;
		const height = window.innerHeight
			? window.innerHeight
			: document.documentElement.clientHeight
				? document.documentElement.clientHeight
				: window.screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top = (height - h) / 2 / systemZoom + dualScreenTop;


		if (
			navigator.userAgent
				.toLowerCase()
				.match(/(ipad|iphone|safari)/) &&
			navigator.userAgent.search("Chrome") < 0
		) {
			setTimeout(() => {
				window.open(url, "_self");
			})
		} else {
			const newWindow = window.open(
				url,
				title,
				`
		  scrollbars=yes,
		  width=${w / systemZoom}, 
		  height=${h / systemZoom}, 
		  top=${top}, 
		  left=${left}
		  `
			);
			if (window.focus) newWindow.focus();
		}
	};
	const handleSubmitPay = () => {
		setLoader(false)
		let data = formData;
		data['isd'] = code;
		data['packageId'] = process.env.REACT_APP_PACKAGE_ID;
		if (process.env.REACT_APP_PROMOTION_ID != "null") {
			data['promotionId'] = process.env.REACT_APP_PROMOTION_ID;
		}

		const submitData = JSON.stringify(data);
		const privyrData =  JSON.stringify({...data,lead_source: "Red2 Page",phone:phoneData})

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: 'https://www.privyr.com/integrations/api/v1/incoming-webhook',
			headers: { 
			  'X-TOKEN': 'hp4iWYK7', 
			  'Content-Type': 'application/json'
			},
			data : privyrData
		  };
		  
		  axios(config)
		  .then((response) => {
			console.log(response)
			console.log(JSON.stringify(response.data));
		  })
		  .catch((error) => {
			console.log(error);
		  });

		axios
			.post(
				// @ts-ignore
				process.env.REACT_APP_JOIN_NOW_URL,
				submitData,

				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((res) => {

				logEvent(analytics, 'User Details Red2',data);

				// if (res.data.message === 'Package not applicable for actively enrolled users.') {
				// 	setError("Already enrolled")
				// 	setisDisable(true)
				// }
				// else {
					const paymentLink = res?.data?.data?.paymentLink;
					// popupCenter(paymentLink, "Payment for Red", 600, 700);

					
					// if (paymentLink) {
						const NewFormData = {
							...data, campaignId: process.env.REACT_APP_CAMPAIGNID, paymentUrl: paymentLink, packageId: process.env.REACT_APP_PACKAGE_ID,
							originCampaign:process.env.REACT_APP_ORIGIN_CAMPAIGN
						}
						const SubmitNewFormData = JSON.stringify(NewFormData)
						// setTimeout(() => {
						axios.post('https://ser.ay.tudotechnologies.com/magicFood/registerUserPayNow/', SubmitNewFormData,
							{
								headers: {
									"Content-Type": "application/json",
								},
							}
						)
							.then((res) => {
								console.log("Data",res)
								logEvent(analytics,"Conversion Event Red2",data)
								props.onHide();
							})
							.catch((err) => console.log(err));

						// setTimeout(() => {
						// 	popupCenter(paymentLink, "Payment for Red", 600, 700);
						// }, 500);
						props.ThankYouPopUpShow()
						// }, 5000);
					// }
				// }
				// window.open(paymentLink, "_parent");
			}
			)
		// .catch((err) => console.log(err));

	};





	return (
		<div className='congratulations pt-4'>
			<div className='header'>
				<div className='px-5 d-flex align-items-center justify-content-center position-relative'>
					<h1 className='title'>Hello! </h1>
					<p
						onClick={() => {
							props.onHide();
						}}
						className='position-absolute'
						style={{ right: 0, top: 0, paddingRight: "50px" }}>
						<img
							src='assets/close.svg'
							alt=''
							className='img-fluid'
						/>
					</p>
				</div>
				<div className='px-4 brief d-flex align-items-center '>
					Please enter your details and our representative will contact you to book your FREE RED class!
				</div>

			</div>
			<div className='px-4 '>
				<div className='d-flex flex-column mt-3'>
					<label htmlFor='Name'>Name</label>
					<input
						onChange={handleFormData}
						placeholder='Enter your name'
						type='text'
						id='Name'
						name='name'
					/>
				</div>
				<div className='d-flex flex-column mt-3'>
					<label htmlFor='Email'>Email Address</label>
					<input
						onChange={handleFormData}
						name='email'
						placeholder='Enter your email'
						type='email'
						id='Email'
					/>
				</div>
				{/* <div className='d-flex flex-column mt-3'>
					<label htmlFor='Mobile'>Phone Number</label>
					<input
						onChange={handleFormData}
						placeholder='Enter your mobile'
						type='text'
						id='Mobile'
						name='mobile'
					/>
				</div> */}
				<div className='d-flex flex-column mt-3'>
					<label htmlFor="email">Phone No</label>


					<PhoneInput
						// @ts-ignore
						className="joinNowinput"
						onChange={handleChange}
						placeholder='Enter your mobile'
						type='text'
						id='Mobile'
						country={location?.country?.toLowerCase()}
					/>

				</div>
				<div className='d-flex align-items-center justify-content-center  '>
					{Loader ? <button
						disabled={isDisable}
						onClick={handleSubmitPay}
						className='proceedBtn'>
						Proceed
					</button> :

						<button
							onClick={handleSubmitPay}
							className='loadingButton'>
							<ReactLoading type={"spinningBubbles"} height={38} width={38} color={"#F21623"} />

						</button>}

				</div>
				{error && <div className="warning">
					<div className="d-flex justify-content-center align-items-center ">
						<img src="assets/Warning.png" alt="" />
						<p>{error}</p>
					</div>

				</div>}
			</div>
		</div>
	);
}
