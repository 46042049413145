import React from "react";
import "./JoinAdd.css";
import { logEvent,getAnalytics } from "firebase/analytics";
import moment from "moment";

export default function JoinAdd({ modalHandler , offerDate}) {

	const analytics = getAnalytics()

	const handleModal = () => {
		modalHandler(true)
		logEvent(analytics, 'Clicked On Join Today Red2');
	}

	const handleDate = () => {

		const currentDate = moment()
		const isoFormat = moment(offerDate * 1000).format()
		// console.log(moment(isoFormat).isBefore(currentDate))


		if(offerDate && moment(isoFormat).isAfter(currentDate)){
			return moment(offerDate * 1000).format('Do MMM, YYYY')
		}
		else if(offerDate && moment(isoFormat).isBefore(currentDate)){
			return moment().endOf('month').format('Do MMM, YYYY');
		}
		
		else if(offerDate && moment(offerDate * 1000).format('Do MMM') !== moment().endOf('month').format('Do MMM') ){
			return moment(offerDate * 1000).format('Do MMM, YYYY')
		}


        return moment().endOf('month').format("Do MMM, YYYY")
    }

	return (
		<>
			<div className='joinAd flex-column '>
				{/* <div className='NeverBefore d-flex align-items-center justify-content-center'>
					Never Before Offer
				</div> */}
				<div
					style={{ cursor: "pointer" }}
					onClick={() => handleModal()}>
					<img
						src='assets/FreeTrialButtonWeb.svg'
						alt=''
						className='img-fluid d-none d-sm-inline-block d-md-none d-lg-inline-block' />
					<img
						width="257px"
						height='48px'
						src='assets/FreeTrialButtonMoble.svg'
						alt=''
						className='  d-sm-none d-md-inline-block d-inline-block d-lg-none'
					/>

					{/* Join Today for just{" "}
					<span className='strikethrough'>350</span>{" "}
					<span style={{ fontSize: "28px" }}>200 </span> AED */}

				</div>
				<p className='firstMonthOff text-center'>
					*Offer ends at {handleDate()}

				</p>

			</div>
		</>
	);
}
