import React from "react";
import AboutBharatThakur from "../AboutBharatThakur/AboutBharatThakur";
import Advertisement from "../Advertisement/Advertisement";
import Benefits from "../Benefits/Benefits";
import ClientsReview from "../ClientsReview/ClientsReview";
import FAQ from "../FAQ/FAQ";
import HourDayComparism from "../HourDayComparism/HourDayComparism";
import Nav from "../Nav/Nav";
import OurTeachers from "../OurTeachers/OurTeachers";
import RedBanner from "../RedBanner/RedBanner";
import WhyRed from "../WhyRed/WhyRed";
import "./HomePage.css";
import DownloadEbook from "../DownloadEbook/DownloadEbook";
import DownloadEbooknew from "../DownloadEbook/DownloadEbooknew";
import Footer from "../Footer/Footer";
import JoinNowModal from "../JoinNowModal/JoinNowModal";
import TimeSchedule from "../TimeSchedule/TimeSchedule";
import ThankYouPopUp from "../ThankYouPopUp/ThankYouPopUp";
export default function HomePage() {
	const [modalShow, setModalShow] = React.useState(false);
	const [ShowThanksPopUp, setShowThanksPopUp] = React.useState(false);

    const ThankYouPopUpHide = () => setShowThanksPopUp(false);
    const ThankYouPopUpShow = () => setShowThanksPopUp(true);
	return (
		<>
			<JoinNowModal ThankYouPopUpShow={ThankYouPopUpShow} show={modalShow} onHide={() => setModalShow(false)} />
			 <ThankYouPopUp ThankYouPopUpHide={ThankYouPopUpHide} ShowThanksPopUp={ShowThanksPopUp} />

			<div id='home'>
				<Nav modalHandler={setModalShow} />
				<div className='px-3 px-sm-5'>
					<RedBanner modalHandler={setModalShow}  />
					<ClientsReview />
					<Benefits />
					<Advertisement modalHandler={setModalShow} type={1} />
					<WhyRed />
					<Advertisement modalHandler={setModalShow} type={2} />
					<HourDayComparism />
					<OurTeachers />
					<Advertisement modalHandler={setModalShow} type={3} />
					<AboutBharatThakur />
					<TimeSchedule/>
					<FAQ />
				</div>
				{/* <DownloadEbook /> */}
				<DownloadEbooknew/>
				<Footer />
			</div>
		</>
	);
}
