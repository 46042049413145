import React from "react";
import Footer from "../Footer/Footer";
import JoinNowModal from "../JoinNowModal/JoinNowModal";
import Nav from "../Nav/Nav";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import ThankYouPopUp from "../ThankYouPopUp/ThankYouPopUp";

export default function TermsConditionPage() {
    const [modalShow, setModalShow] = React.useState(false);
    const [ShowThanksPopUp, setShowThanksPopUp] = React.useState(false);

    const ThankYouPopUpHide = () => setShowThanksPopUp(false);
    const ThankYouPopUpShow = () => setShowThanksPopUp(true);
    return (

        <>
            <JoinNowModal ThankYouPopUpShow={ThankYouPopUpShow} show={modalShow} onHide={() => setModalShow(false)} />
            <ThankYouPopUp ThankYouPopUpHide={ThankYouPopUpHide} ShowThanksPopUp={ShowThanksPopUp} />
            <div id='home'>
                <Nav modalHandler={setModalShow} />
                <TermsAndConditions />
                <Footer />
            </div>
        </>
    );
}
