import React from "react";
import "./JoinAdd.css";
import moment from "moment";

export default function JoinAddBanner({ modalHandler }) {

    const handleDate = () => {
        return moment().endOf('month').format("Do MMM, YYYY")
    }

    return (
        <>
            <div className='joinAd flex-column '>
                {/* <div className='NeverBefore d-flex align-items-center justify-content-center'>
					Never Before Offer
				</div> */}
                <p className='firstMonthOff priceOffer OfferDescription'>
                    Get your first month of our RED Online Program for just  <span className="PreviousPriceBanner">350 <img className="crossLinelBanner" src="assets/Line.svg" alt="" /></span> <span className="LatestPrice">299</span> AED. <br/>
                </p>
                <div className="OfferDescription  OfferDescriptionResp">
                        Get your first month of our RED <br/>  Online Program
                        for  just <br/> <span className="PreviousPrice">350 <img className="crossLine" src="assets/Line.svg" alt="" /></span>
                        <span className="LatestPrice">299</span> AED

                </div>


                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => modalHandler(true)}>
                    <img
                        src='assets/FreeTrialButtonWeb.svg'
                        alt=''
                        className=' d-none d-sm-inline-block d-md-none d-lg-inline-block' />
                    <img
                        width="257px"
                        height="48px"
                        src='assets/FreeTrialButtonMoble.svg'
                        alt=''
                        className='  d-sm-none d-md-inline-block d-inline-block d-lg-none'
                    />

                    {/* Join Today for just{" "}
					<span className='strikethrough'>350</span>{" "}
					<span style={{ fontSize: "28px" }}>200 </span> AED */}

                </div>
                <p className='firstMonthOff'>
                    *Offer ends at {handleDate()}
                </p>

            </div>
        </>
    );
}
