import React from 'react';
import { Modal } from 'react-bootstrap';
import './ThankYouPopUp.css'
const ThankYouPopUp = ({ ThankYouPopUpHide, ShowThanksPopUp }) => {

    return (
        <Modal dialogClassName='modal-60w ' centered show={ShowThanksPopUp} onHide={ThankYouPopUpHide}>
            <div className='ThankYouWrapper'>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="py-4">
                    <div className="d-flex justify-content-center">
                        <div>
                            <div className='d-flex justify-content-center'>
                                <img src="assets/Hands.svg" alt="" />
                            </div>
                            <div >
                                <p className='text-center ThankYouTittle'>Thank You</p>
                                <p className='text-center ThankYouDescription'>
                                    One of us will connect
                                    with you at the earliest
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </div>

        </Modal>

    );
};

export default ThankYouPopUp;